::-moz-selection {
  background-color: #cea06c;
  color: #fff;
}
::selection {
  background-color: #cea06c;
  color: #fff;
}
::-webkit-input-placeholder {
  color: #777777;
  font-weight: 300;
}
:-moz-placeholder {
  color: #777777;
  opacity: 1;
  font-weight: 300;
}
::-moz-placeholder {
  color: #777777;
  opacity: 1;
  font-weight: 300;
}
:-ms-input-placeholder {
  color: #777777;
  font-weight: 300;
}
::-ms-input-placeholder {
  color: #777777;
  font-weight: 300;
}
body {
  color: #87898f;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
select {
  display: block;
}
figure {
  margin: 0;
}
a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
iframe {
  border: 0;
}
a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus {
  text-decoration: none;
  outline: 0;
}
.card-panel {
  margin: 0;
  padding: 60px;
}
.btn i,
.btn-large i,
.btn-floating i,
.btn-large i,
.btn-flat i {
  font-size: 1em;
  line-height: inherit;
}
.gray-bg {
  background: #f9f9ff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Condensed", sans-serif;
  color: #fffaf4;
  line-height: 1.1em;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 700;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  color: #fffaf4;
}
h1,
.h1 {
  font-size: 36px;
}
h2,
.h2 {
  font-size: 42px;
}
h3,
.h3 {
  font-size: 24px;
}
h4,
.h4 {
  font-size: 20px;
}
h5,
.h5 {
  font-size: 16px;
}
h6,
.h6 {
  font-size: 14px;
}
td,
th {
  border-radius: 0px;
}
.clear::before,
.clear::after {
  content: " ";
  display: table;
}
.clear::after {
  clear: both;
}
.fz-11 {
  font-size: 11px;
}
.fz-12 {
  font-size: 12px;
}
.fz-13 {
  font-size: 13px;
}
.fz-14 {
  font-size: 14px;
}
.fz-15 {
  font-size: 15px;
}
.fz-16 {
  font-size: 16px;
}
.fz-18 {
  font-size: 18px;
}
.fz-30 {
  font-size: 30px;
}
.fz-48 {
  font-size: 48px !important;
}
.fw100 {
  font-weight: 100;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500;
}
.f700 {
  font-weight: 700;
}
.fsi {
  font-style: italic;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-150 {
  margin-top: 150px;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-15 {
  margin-right: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-50 {
  margin-right: 50px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-0-i {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.pt-0 {
  padding-top: 0px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-150 {
  padding-top: 150px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pr-30 {
  padding-right: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-90 {
  padding-left: 90px;
}
.p-40 {
  padding: 40px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.text-italic {
  font-style: italic;
}
.text-white {
  color: #9b8c81;
}
.transition {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.section-full {
  padding: 100px 0;
}
.section-half {
  padding: 75px 0;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-rigth {
  text-align: right;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.flex-grow {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
}
.flex-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
.flex-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}
.flex-top {
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.flex-bottom {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}
.flex-column {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.display-table {
  display: table;
}
.light {
  color: #fff;
}
.dark {
  color: #222;
}
.relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
.container.fullwidth {
  width: 100%;
}
.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.no-padding {
  padding: 0;
}
.section-bg {
  background: #f9fafc;
}
@media (max-width: 767px) {
  .no-flex-xs {
    display: block !important;
  }
}
.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}
.section-gap {
  padding: 200px 0 5px;
}
.section-gap2 {
  padding: 200px 0 5px;
}
@media (max-width: 991px) {
  .section-gap {
    padding: 200px 0 5px;
  }
  .section-gap2 {
    padding: 200px 0 5px;
  }
}
.section-gap-top {
  padding-top: 130px;
}
@media (max-width: 991px) {
  .section-gap-top {
    padding-top: 70px;
  }
}
.section-gap-bottom {
  padding-bottom: 130px;
}
@media (max-width: 991px) {
  .section-gap-bottom {
    padding-bottom: 70px;
  }
}
.section-title {
  text-align: center;
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  .section-title {
    margin-bottom: 40px;
  }
}
.section-title h4 {
  color: #cea06c;
  font-weight: 400;
}
@media (max-width: 767px) {
  .section-title h4 {
    margin-bottom: 10px;
  }
}
.section-title h2 {
  font-size: 42px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #9b8c81;
}
@media (max-width: 991px) {
  .section-title h2 {
    margin-bottom: 5px;
    margin-top: 0px;
  }
}
@media (max-width: 991px) {
  .section-title h2 {
    font-size: 30px;
  }
}
.primary-btn {
  position: relative;
  display: inline-block;
  color: #fffaf4;
  padding: 12px 35px;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  outline: none !important;
  text-align: center;
  cursor: pointer;
  text-transform: capitalize;
  background: transparent;
  border: 1px solid #cea06c;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.primary-btn:hover {
  background: #cea06c;
  color: #fff;
}
.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.video-play-button {
  position: relative;
  box-sizing: content-box;
  display: inline-block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 46px 8px 18px 28px;
}
.video-play-button:before,
.video-play-button:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
}
.video-play-button:before {
  z-index: 0;
  -webkit-animation: pulse-border 2000ms ease-out infinite;
  -moz-animation: pulse-border 2000ms ease-out infinite;
  -o-animation: pulse-border 2000ms ease-out infinite;
  animation: pulse-border 2000ms ease-out infinite;
}
.video-play-button:after {
  z-index: 1;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 16px solid #cea06c;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}
@-moz-keyframes pulse-border {
  0% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}
@-o-keyframes pulse-border {
  0% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
    transform: translate(-50%, -50%) translateZ(0) scale(1);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    transform: translate(-50%, -50%) translateZ(0) scale(1.5);
    opacity: 0.1;
  }
}
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Flaticon.woff2") format("woff2"),
    url("../fonts/Flaticon.woff") format("woff"),
    url("../fonts/Flaticon.ttf") format("truetype"),
    url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}
.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
.flaticon-compass:before {
  content: "\f100";
}
.flaticon-desk:before {
  content: "\f101";
}
.flaticon-bathroom:before {
  content: "\f102";
}
.flaticon-beach:before {
  content: "\f103";
}
.sticky-wrapper {
  position: absolute;
  width: 100%;
  height: 80px !important;
}
.sticky-wrapper.is-sticky .default-header {
  background: #fffaf4;
  box-shadow: -21.213px 21.213px 30px 0px rgba(158, 158, 158, 0.3);
}
.default-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background: transparent;
}
@media (max-width: 991px) {
  .default-header {
    background: #fffaf4;
  }
}
.default-header .navbar {
  padding: 22px 16px;
  background-color: #fffaf4;
}
.default-header .navbar .navbar-toggler {
  border: 1px solid #fff;
  border-color: #fff;
  cursor: pointer;
}
.default-header .navbar .navbar-toggler:focus {
  outline: none;
}
.default-header .navbar .navbar-toggler span {
  color: #9b8c81;
}
.default-header .dropdown-item {
  font-size: 14px;
  width: auto !important;
  text-align: left;
}
@media (max-width: 767px) {
  .default-header .dropdown-item {
    text-align: left;
    padding: 0.25rem;
  }
}
.default-header .dropdown-item {
  font-size: 14px;
  width: auto !important;
  text-align: left;
}
@media (max-width: 767px) {
  .default-header .dropdown-item {
    text-align: left;
    padding: 0.25rem;
  }
}
@media (min-width: 991px) {
  .default-header .dropdown .dropdown-menu {
    display: none;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    -ms-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
  }
  .default-header .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
.default-header .dropdown-menu {
  border-radius: 0;
  margin-top: 27px;
  border: none;
}
@media (max-width: 991px) {
  .default-header .dropdown-menu {
    margin-top: 0px;
  }
}
.default-header .dropdown-menu a {
  padding: 5px 15px;
}
@media (max-width: 767px) {
  .default-header .dropdown-menu {
    margin-top: 0px;
  }
}
.default-header .dropdown-item:focus,
.default-header .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: transparent;
}
.menu-bar {
  cursor: pointer;
}
.menu-bar span {
  color: #222;
  font-size: 24px;
}
@media (max-width: 992px) {
  .navbar-nav {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
  }
}
.main-menubar {
  display: none !important;
}
@media (max-width: 991.98px) {
  .main-menubar {
    display: block !important;
  }
}
.navbar-nav a {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: #9b8c81;
  padding: 32px 20px;
}
.navbar-nav a:hover,
.navbar-nav a.active {
  color: #cea06c;
}
@media (max-width: 992px) {
  .navbar-nav {
    margin-top: 10px;
  }
  .navbar-nav a {
    padding: 0;
  }
  .navbar-nav li {
    padding: 10px 0;
  }
}
@media (max-width: 991px) {
  .navbar-nav .dropdown .dropdown-menu {
    background: transparent;
  }
}
.navbar-nav .dropdown .dropdown-item {
  color: #fffaf4;
  text-transform: capitalize;
}
.navbar-nav .dropdown .dropdown-item:hover {
  color: #cea06c;
}
@media (max-width: 991px) {
  .navbar-nav .dropdown .dropdown-item {
    color: #fff;
  }
}
.parallax-mirror {
  max-height: 900px;
}
.home-banner-area .template-name {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  top: 24%;
  left: -1%;
  font-size: 150px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.05);
}
@media (max-width: 767px) {
  .home-banner-area .template-name {
    display: none;
  }
}
.home-banner-area .fullscreen {
  height: 900px;
}
@media (max-width: 1199.98px) {
  .home-banner-area .fullscreen {
    height: 700px;
  }
}
.home-banner-area .overlay {
  background: rgba(5, 10, 52, 0.6);
}
.home-banner-area .banner-content {
  margin-top: 25%;
}
@media (max-width: 991px) {
  .home-banner-area .banner-content {
    margin-top: 37%;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .home-banner-area .banner-content {
    margin-top: 50%;
    text-align: center;
  }
}
.home-banner-area .banner-content h1 {
  color: #fff;
  font-size: 100px;
  line-height: 100px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .home-banner-area .banner-content h1 {
    font-size: 45px;
    line-height: 60px;
  }
}
@media (max-width: 991.98px) {
  .home-banner-area .banner-content h1 br {
    display: none;
  }
}
@media (max-width: 414px) {
  .home-banner-area .banner-content h1 {
    font-size: 40px;
    line-height: 50px;
  }
}
.home-banner-area .banner-content p {
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
}
.home-banner-area .banner-content .primary-btn {
  border: 1px solid #fff;
  color: #fff;
  margin-top: 20px;
}
.home-banner-area .banner-content .primary-btn:hover {
  border: 1px solid #cea06c;
}
.banner-area {
  background-size: cover;
}
.banner-area .banner-content {
  min-height: 450px;
}
.banner-area .overlay-bg {
  background: rgba(7, 4, 31, 0.6);
}
.banner-area h1 {
  font-size: 50px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .banner-area h1 {
    font-size: 35px;
  }
}
.banner-area p {
  margin-bottom: 0px;
  color: #fff;
}
.head-bottom-meta {
  position: absolute;
  bottom: 50px;
  left: 50px;
  color: #fff;
}
@media (max-width: 991px) {
  .head-bottom-meta {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.head-bottom-meta .meta-left a {
  margin-right: 20px;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.head-bottom-meta .meta-left a:hover {
  color: #cea06c;
}
@media (max-width: 767px) {
  .head-bottom-meta .flex-row {
    flex-direction: column !important;
  }
  .head-bottom-meta .d-flex {
    display: block !important;
  }
}
.top-section-area #imaginary_container {
  background: #fff;
  padding: 24px;
}
.top-section-area .stylish-input-group .form-control {
  border-right: 0;
  box-shadow: 0 0 0;
  border-color: #eee;
  background-color: #f7f7f7;
}
.top-section-area .input-group-addon {
  background-color: #f7f7f7 !important;
  border-color: #eee;
}
.top-section-area .stylish-input-group button {
  border: 0;
  background: transparent;
}
.top-section-area input {
  font-size: 14px;
}
.features-area {
  padding-bottom: 80px;
}
@media (max-width: 991px) {
  .features-area {
    padding-bottom: 20px;
  }
}
.feature-item {
  padding: 0px 17px;
  text-align: center;
  margin-bottom: 50px;
}
.feature-item i {
  margin-bottom: 35px;
  font-size: 65px;
  border: 1px solid #9499a5;
  padding: 36px 41px;
  display: inline-block;
  color: #fffaf4;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 767px) {
  .feature-item i {
    margin-bottom: 20px;
  }
}
.feature-item h4 {
  font-size: 20px;
  margin-bottom: 15px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.feature-item p {
  margin-bottom: 0px;
}
.feature-item:hover i {
  border: 1px solid #cea06c;
}
.feature-item:hover h4 {
  color: #cea06c;
}
.about-area {
  background: #f7f7f7;
}
@media (max-width: 991px) {
  .about-area .about-left {
    margin-bottom: 50px;
  }
}
.about-right {
  padding-left: 40px;
}
@media (max-width: 1199px) {
  .about-right {
    padding-left: 15px;
  }
}
.about-right .section-title {
  margin-bottom: 20px;
}
.about-right .primary-btn {
  margin-top: 35px;
}
.about-right p:last-child {
  margin-bottom: 0px;
}
.about-content {
  margin-top: 135px;
  margin-bottom: 112px;
  color: #fff;
  position: relative;
}
@media (max-width: 767px) {
  .about-content {
    margin-top: 140px;
  }
}
.offer-area {
  background: #f7f7f7;
  padding: 130px 0px 100px;
}
@media (max-width: 991px) {
  .offer-area {
    padding: 70px 0px 40px;
  }
}
.offer-area:after {
  position: absolute;
  content: "";
  top: 70px;
  left: 0;
  width: 36%;
  bottom: 70px;

  z-index: 1;
}
@media (max-width: 1199px) {
  .offer-area:after {
    width: 32%;
  }
}
@media (max-width: 991px) {
  .offer-area:after {
    display: none;
  }
}
@media (max-width: 991px) {
  .offer-area .justify-content-end {
    justify-content: flex-start !important;
  }
}
.offer-area .offer-right {
  padding-left: 35px;
}
@media (max-width: 1024px) {
  .offer-area .offer-right {
    padding-left: 15px;
  }
}
.offer-area .single-offer {
  padding: 50px;
  background: #fff;
  margin-bottom: 30px;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.offer-area .single-offer h4 {
  margin: 0px 0px 20px;
  font-weight: 400;
}
.offer-area .single-offer p {
  margin-bottom: 0px;
}
.offer-area .single-offer:hover {
  border: 1px solid #cea06c;
}
.project-area {
  padding-bottom: 100px;
}
@media (max-width: 991px) {
  .project-area {
    padding-bottom: 40px;
  }
}
.project-area .filters {
  text-align: center;
}
@media (max-width: 991px) {
  .project-area .filters {
    margin-top: 40px;
  }
}
.project-area .filters ul {
  padding: 0;
}
@media (max-width: 991px) {
  .project-area .filters ul {
    text-align: center;
  }
}
.project-area .filters ul li {
  list-style: none;
  display: inline-block;
  padding: 0 15px;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  color: #87898f;
}
.project-area .filters ul li:last-child {
  padding-right: 0;
}
@media (max-width: 991px) {
  .project-area .filters ul li:first-child {
    padding-left: 0;
  }
}
.project-area .filters ul li.active,
.project-area .filters ul li:hover {
  color: #9b8c81;
}
.project-area .filters-content .show {
  opacity: 1;
  visibility: visible;
  transition: all 350ms;
}
.project-area .filters-content .hide {
  opacity: 0;
  visibility: hidden;
  transition: all 350ms;
}
.project-area .filters-content img {
  border-radius: 10px;
  width: 100%;
}
.single-project {
  border-radius: 5px;
  margin-bottom: 30px;
}
.single-project .thumb .image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.single-project .middle {
  transition: 0.5s ease;
  position: absolute;
  right: 0;
  top: 0px;
  min-width: 0px;
  background: rgba(250, 250, 250, 0.4);
  box-shadow: 0px -10px 30px rgba(38, 58, 78, 0.1);
  padding: 4px 10px;
}
@media (min-width: 1200px) {
  .single-project .middle {
    right: 10px;
  }
}
.single-project .middle h4 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #9b8c81;
}
.single-project .middle .cat {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-project:hover .middle .cat {
  color: #cea06c;
}
.callto-area {
  background-size: cover;
  text-align: center;
}
.callto-area .call-wrap {
  position: relative;
}
.callto-area h1 {
  font-size: 42px;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}
.callto-area p {
  margin-bottom: 0px;
  font-weight: 500;
  margin-top: 20px;
  color: #fff;
}
.callto-area .overlay-bg {
  background: rgba(5, 10, 52, 0.7);
}
.testimonial-area {
  background: #f7f7f7;
}
.testimonial-area .owl-controls {
  margin-top: 70px;
}
@media (max-width: 991px) {
  .testimonial-area .owl-controls {
    display: none;
  }
}
.testimonial-area .owl-dots {
  text-align: center;
  bottom: 5px;
  margin-top: 0px;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
.testimonial-area .owl-dot {
  height: 4px;
  width: 20px;
  display: inline-block;
  background: rgba(127, 127, 127, 0.5);
  margin: 0px 10px;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.testimonial-area .owl-dot.active {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  padding: 0px 25px 0px 0px;
  background: #cea06c;
}
.single-testimonial {
  background-color: #fff;
  padding: 30px;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-testimonial h4 {
  margin: 20px 0px 10px;
  font-size: 18px;
  text-transform: uppercase;
}
.single-testimonial p {
  margin-bottom: 0px;
}
.single-testimonial .thumb {
  margin-right: 20px;
}
.single-testimonial .thumb img {
  width: auto;
}
.single-testimonial:hover {
  border: 1px solid #cea06c;
}
.brands-area {
  padding: 60px 0px;
}
.brands-area .brand-wrap {
  padding: 40px 0;
}
.brands-area .brand-wrap .owl-carousel .owl-item img {
  width: auto !important;
}
.brands-area .brand-wrap .single-brand {
  text-align: center;
  filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  opacity: 0.3;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.brands-area .brand-wrap .single-brand:hover {
  filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  opacity: 1;
}
.mapBox {
  height: 420px;
  margin-bottom: 80px;
}
.contact_info .info_item {
  position: relative;
  padding-left: 45px;
  color: #9b8c81;
}
.contact_info .info_item i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  line-height: 24px;
  color: #9b8c81;
  font-weight: 600;
}
.contact_info .info_item h6 {
  font-size: 16px;
  line-height: 24px;
  color: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
  color: #9b8c81;
}
.contact_info .info_item h6 a {
  color: #9b8c81;
}
.contact_info .info_item p {
  font-size: 14px;
  line-height: 24px;
  padding: 2px 0px;
}
.contact_form .form-group {
  margin-bottom: 10px;
}
.contact_form .form-group .form-control {
  line-height: 26px;
  color: #999;
  border: 1px solid #eeeeee;
  font-family: "Roboto", sans-serif;
  border-radius: 0px;
  padding-left: 20px;
}
.contact_form .form-group .form-control:focus {
  box-shadow: none;
  outline: none;
}
.contact_form .form-group .form-control.placeholder {
  color: #fffaf4;
  font-size: 13px;
}
.contact_form .form-group .form-control:-moz-placeholder {
  color: #fffaf4;
  font-size: 13px;
}
.contact_form .form-group .form-control::-moz-placeholder {
  color: #fffaf4;
  font-size: 13px;
}
.contact_form .form-group .form-control::-webkit-input-placeholder {
  color: #fffaf4;
  font-size: 13px;
}
.contact_form .form-group textarea {
  resize: none;
}
.contact_form .form-group textarea.form-control {
  height: 140px;
}
.contact_form .primary-btn {
  margin-top: 20px;
  cursor: pointer;
}
.modal-message .modal-dialog {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  margin: 0px;
  max-width: 500px;
  width: 100%;
}
.modal-message .modal-dialog .modal-content .modal-header {
  text-align: center;
  display: block;
  border-bottom: none;
  padding-top: 50px;
  padding-bottom: 50px;
}
.modal-message .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: -15px;
  top: -15px;
  padding: 0px;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}
.modal-message .modal-dialog .modal-content .modal-header h2 {
  display: block;
  text-align: center;
  color: #cea06c;
  padding-bottom: 10px;
  font-family: "Roboto", sans-serif;
}
.modal-message .modal-dialog .modal-content .modal-header p {
  display: block;
}
.blog-home-banner {
  background-size: cover;
  padding: 130px 0px;
}
.blog-home-banner h1 span {
  color: #cea06c;
}
.blog-home-banner .primary-btn {
  color: #fff;
  background: #cea06c;
}
.blog-home-banner .primary-btn:hover {
  color: #cea06c;
  background: #fff;
}
.blog-home-banner .overlay-bg {
  background: rgba(0, 0, 0, 0.3);
}
.blog-page {
  background: #f9f9ff;
}
.blog-header-content h1 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 414px) {
  .blog-header-content h1 {
    font-size: 30px;
  }
}
.blog-header-content p {
  margin: 20px 0px;
}
.top-category-widget-area {
  background-color: #f9f9ff;
}
.top-category-widget-area .single-cat-widget {
  position: relative;
  text-align: center;
}
.top-category-widget-area .single-cat-widget .overlay-bg {
  background: rgba(0, 0, 0, 0.85);
  margin: 7%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.top-category-widget-area .single-cat-widget img {
  width: 100%;
}
.top-category-widget-area .single-cat-widget .content-details {
  top: 32%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  bottom: 0px;
  position: absolute;
}
.top-category-widget-area .single-cat-widget .content-details h4 {
  color: #fff;
}
.top-category-widget-area .single-cat-widget .content-details span {
  display: inline-block;
  background: #fff;
  width: 60%;
  height: 1%;
}
.top-category-widget-area .single-cat-widget:hover .overlay-bg {
  background: rgba(206, 160, 108, 0.85);
  margin: 7%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991px) {
  .top-category-widget-area .single-cat-widget {
    margin-bottom: 50px;
  }
}
.post-content-area {
  background-color: #f9f9ff;
}
.post-content-area .single-post {
  margin-bottom: 50px;
}
.post-content-area .single-post .meta-details {
  text-align: right;
  margin-top: 35px;
}
@media (max-width: 767px) {
  .post-content-area .single-post .meta-details {
    text-align: left;
  }
}
.post-content-area .single-post .meta-details .tags {
  margin-bottom: 30px;
}
.post-content-area .single-post .meta-details .tags li {
  display: inline-block;
  font-size: 14px;
}
.post-content-area .single-post .meta-details .tags li a {
  color: #fffaf4;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.post-content-area .single-post .meta-details .tags li a:hover {
  color: #cea06c;
}
@media (max-width: 1024px) {
  .post-content-area .single-post .meta-details {
    margin-top: 0px;
  }
}
.post-content-area .single-post .user-name a,
.post-content-area .single-post .date a,
.post-content-area .single-post .view a,
.post-content-area .single-post .comments a {
  color: #87898f;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.post-content-area .single-post .user-name a:hover,
.post-content-area .single-post .date a:hover,
.post-content-area .single-post .view a:hover,
.post-content-area .single-post .comments a:hover {
  color: #cea06c;
}
.post-content-area .single-post .user-name .lnr,
.post-content-area .single-post .date .lnr,
.post-content-area .single-post .view .lnr,
.post-content-area .single-post .comments .lnr {
  font-weight: 900;
  color: #fffaf4;
}
.post-content-area .single-post .feature-img img {
  width: 100%;
}
.post-content-area .single-post .posts-title h3 {
  margin: 20px 0px;
}
.post-content-area .single-post .excert {
  margin-bottom: 20px;
}
.post-content-area .single-post .primary-btn {
  background: #fff !important;
  color: #fffaf4 !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.post-content-area .single-post .primary-btn:hover {
  background: #cea06c !important;
  color: #fff !important;
}
.posts-list .posts-title h3 {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.posts-list .posts-title h3:hover {
  color: #cea06c;
}
.blog-pagination {
  padding-top: 60px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .blog-pagination {
    padding-top: 20px;
    padding-bottom: 50px;
  }
}
.blog-pagination .page-link {
  border-radius: 0;
}
.blog-pagination .page-item {
  border: none;
}
.page-link {
  background: transparent;
  font-weight: 400;
}
.blog-pagination .page-item.active .page-link {
  background-color: #cea06c;
  border-color: transparent;
  color: #fff;
}
.blog-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #87898f;
  border: none;
}
.blog-pagination .page-link .lnr {
  font-weight: 600;
}
.blog-pagination .page-item:last-child .page-link,
.blog-pagination .page-item:first-child .page-link {
  border-radius: 0;
}
.blog-pagination .page-link:hover {
  color: #fff;
  text-decoration: none;
  background-color: #cea06c;
  border-color: #eee;
}
.sidebar-widgets {
  padding-bottom: 120px;
}
.widget-wrap {
  background: #fafaff;
  padding: 20px 0px;
  border: 1px solid #eee;
}
@media (max-width: 991px) {
  .widget-wrap {
    margin-top: 50px;
  }
}
.widget-wrap .single-sidebar-widget {
  margin: 30px 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
}
.widget-wrap .single-sidebar-widget:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}
.widget-wrap .search-widget form.search-form input[type="text"] {
  color: #fff;
  padding: 10px 22px;
  font-size: 14px;
  border: none;
  float: left;
  width: 80%;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  background: #cea06c;
}
.widget-wrap .search-widget ::-webkit-input-placeholder {
  color: #fff;
}
.widget-wrap .search-widget ::-moz-placeholder {
  color: #fff;
}
.widget-wrap .search-widget :-ms-input-placeholder {
  color: #fff;
}
.widget-wrap .search-widget :-moz-placeholder {
  color: #fff;
}
.widget-wrap .search-widget form.search-form button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #cea06c;
  color: #fff;
  font-size: 17px;
  border: none;
  cursor: pointer;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
.widget-wrap .search-widget form.search-form::after {
  content: "";
  clear: both;
  display: table;
}
.widget-wrap .user-info-widget {
  text-align: center;
}
.widget-wrap .user-info-widget a h4 {
  margin-top: 40px;
  margin-bottom: 5px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .user-info-widget .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}
.widget-wrap .user-info-widget .social-links li a {
  color: #fffaf4;
  padding: 10px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .user-info-widget .social-links li a:hover {
  color: #cea06c;
}
.widget-wrap .popular-post-widget .popular-title {
  background: #cea06c;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}
.widget-wrap .popular-post-widget .popular-post-list {
  margin-top: 30px;
}
.widget-wrap .popular-post-widget .popular-post-list .thumb img {
  width: 100%;
}
.widget-wrap .popular-post-widget .single-post-list {
  margin-bottom: 20px;
}
.widget-wrap .popular-post-widget .single-post-list .details {
  margin-left: 12px;
}
.widget-wrap .popular-post-widget .single-post-list .details h6 {
  font-weight: 600;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .popular-post-widget .single-post-list .details h6:hover {
  color: #cea06c;
}
.widget-wrap .popular-post-widget .single-post-list .details p {
  margin-bottom: 0px;
}
.widget-wrap .ads-widget img {
  width: 100%;
}
.widget-wrap .post-category-widget .category-title {
  background: #cea06c;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}
.widget-wrap .post-category-widget .cat-list li {
  padding-top: 15px;
  padding-bottom: 8px;
  border-bottom: 2px dotted #eee;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .post-category-widget .cat-list li p {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .post-category-widget .cat-list li a {
  color: #87898f;
}
.widget-wrap .post-category-widget .cat-list li:hover {
  border-color: #cea06c;
}
.widget-wrap .post-category-widget .cat-list li:hover p {
  color: #cea06c;
}
.widget-wrap .newsletter-widget .newsletter-title {
  background: #cea06c;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
}
.widget-wrap .newsletter-widget .col-autos {
  width: 100%;
}
.widget-wrap .newsletter-widget p {
  text-align: center;
  margin: 20px 0px;
}
.widget-wrap .newsletter-widget .bbtns {
  background: #cea06c;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 20px;
  border-radius: 0;
}
.widget-wrap .newsletter-widget .input-group-text {
  background: #fff;
  border-radius: 0px;
  vertical-align: top;
}
.widget-wrap .newsletter-widget .input-group-text i {
  color: #ccc;
  margin-top: -1px;
}
.widget-wrap .newsletter-widget .form-control {
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  font-size: 13px;
  color: #ccc;
  padding: 9px;
  padding-left: 0px;
}
.widget-wrap .newsletter-widget .form-control:focus {
  box-shadow: none;
  border-color: #ccc;
}
.widget-wrap .newsletter-widget ::-webkit-input-placeholder {
  font-size: 13px;
  color: #ccc;
}
.widget-wrap .newsletter-widget ::-moz-placeholder {
  font-size: 13px;
  color: #ccc;
}
.widget-wrap .newsletter-widget :-ms-input-placeholder {
  font-size: 13px;
  color: #ccc;
}
.widget-wrap .newsletter-widget :-moz-placeholder {
  font-size: 13px;
  color: #ccc;
}
.widget-wrap .newsletter-widget .text-bottom {
  margin-bottom: 0px;
}
.widget-wrap .tag-cloud-widget .tagcloud-title {
  background: #cea06c;
  color: #fff;
  text-align: center;
  padding: 12px 0px;
  margin-bottom: 30px;
}
.widget-wrap .tag-cloud-widget ul li {
  display: inline-block;
  border: 1px solid #eee;
  background: #fff;
  padding: 4px 14px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.widget-wrap .tag-cloud-widget ul li:hover {
  background: #cea06c;
}
.widget-wrap .tag-cloud-widget ul li:hover a {
  color: #fff;
}
.widget-wrap .tag-cloud-widget ul li a {
  font-size: 12px;
  color: #fffaf4;
}
.single-post-area .meta-details {
  margin-top: 20px !important;
}
.single-post-area .social-links li {
  display: inline-block;
  margin-bottom: 10px;
}
.single-post-area .social-links li a {
  color: #fffaf4;
  padding: 7px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .social-links li a:hover {
  color: #cea06c;
}
.single-post-area .quotes {
  margin-top: 20px;
  padding: 30px;
  background-color: #fff;
  box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
}
.single-post-area .arrow {
  position: absolute;
}
.single-post-area .arrow .lnr {
  font-size: 20px;
  font-weight: 600;
}
.single-post-area .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8);
}
.single-post-area .navigation-area {
  border-top: 1px solid #eee;
  padding-top: 30px;
}
.single-post-area .navigation-area .nav-left {
  text-align: left;
}
.single-post-area .navigation-area .nav-left .thumb {
  margin-right: 20px;
  background: #000;
}
.single-post-area .navigation-area .nav-left .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-left .lnr {
  margin-left: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-left:hover .lnr {
  opacity: 1;
}
.single-post-area .navigation-area .nav-left:hover .thumb img {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .single-post-area .navigation-area .nav-left {
    margin-bottom: 30px;
  }
}
.single-post-area .navigation-area .nav-right {
  text-align: right;
}
.single-post-area .navigation-area .nav-right .thumb {
  margin-left: 20px;
  background: #000;
}
.single-post-area .navigation-area .nav-right .thumb img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-right .lnr {
  margin-right: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-post-area .navigation-area .nav-right:hover .lnr {
  opacity: 1;
}
.single-post-area .navigation-area .nav-right:hover .thumb img {
  opacity: 0.5;
}
@media (max-width: 991px) {
  .single-post-area .sidebar-widgets {
    padding-bottom: 0px;
  }
}
.comments-area {
  background: #fafaff;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-top: 50px;
}
@media (max-width: 414px) {
  .comments-area {
    padding: 50px 8px;
  }
}
.comments-area h4 {
  text-align: center;
  margin-bottom: 50px;
}
.comments-area a {
  color: #fffaf4;
}
.comments-area .comment-list {
  padding-bottom: 30px;
}
.comments-area .comment-list:last-child {
  padding-bottom: 0px;
}
.comments-area .comment-list.left-padding {
  padding-left: 25px;
}
@media (max-width: 413px) {
  .comments-area .comment-list .single-comment h5 {
    font-size: 12px;
  }
  .comments-area .comment-list .single-comment .date {
    font-size: 11px;
  }
  .comments-area .comment-list .single-comment .comment {
    font-size: 10px;
  }
}
.comments-area .thumb {
  margin-right: 20px;
}
.comments-area .date {
  font-size: 13px;
  color: #cccccc;
}
.comments-area .comment {
  color: #777777;
}
.comments-area .btn-reply {
  background-color: #fff;
  color: #fffaf4;
  border: 1px solid #eee;
  padding: 8px 30px;
  display: block;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.comments-area .btn-reply:hover {
  background-color: #cea06c;
  color: #fff;
}
.comment-form {
  background: #fafaff;
  text-align: center;
  border: 1px solid #eee;
  padding: 50px 20px;
  margin-top: 50px;
}
.comment-form h4 {
  text-align: center;
  margin-bottom: 50px;
}
.comment-form .name {
  padding-left: 0px;
}
@media (max-width: 991px) {
  .comment-form .name {
    padding-right: 0px;
  }
}
.comment-form .email {
  padding-right: 0px;
}
@media (max-width: 991px) {
  .comment-form .email {
    padding-left: 0px;
  }
}
.comment-form .form-control {
  padding: 12px;
  background: #fff;
  border: none;
  border-radius: 0px;
  width: 100%;
  font-size: 13px;
  color: #777777;
}
.comment-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #eee;
}
.comment-form ::-webkit-input-placeholder {
  font-size: 13px;
  color: #777;
}
.comment-form ::-moz-placeholder {
  font-size: 13px;
  color: #777;
}
.comment-form :-ms-input-placeholder {
  font-size: 13px;
  color: #777;
}
.comment-form :-moz-placeholder {
  font-size: 13px;
  color: #777;
}
.comment-form .primary-btn {
  margin-top: 20px;
}
.whole-wrap {
  background-color: #f9f9ff;
}
.generic-banner {
  margin-top: 60px;
  background-color: #cea06c;
  text-align: center;
}
.generic-banner .height {
  height: 600px;
}
@media (max-width: 767.98px) {
  .generic-banner .height {
    height: 400px;
  }
}
.generic-banner .generic-banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content h2 br {
    display: none;
  }
}
.generic-banner .generic-banner-content p {
  text-align: center;
  font-size: 16px;
}
@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content p br {
    display: none;
  }
}
.generic-content h1 {
  font-weight: 600;
}
.about-generic-area {
  background: #fff;
}
.about-generic-area p {
  margin-bottom: 20px;
}
.white-bg {
  background: #fff;
}
.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee;
}
.switch-wrap {
  margin-bottom: 10px;
}
.switch-wrap p {
  margin: 0;
}
.sample-text-area {
  background: #f9f9ff;
  padding: 100px 0 70px 0;
}
.sample-text {
  margin-bottom: 0;
}
.text-heading {
  margin-bottom: 30px;
  font-size: 24px;
}
.typo-list {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .typo-sec {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .element-wrap {
    margin-top: 30px;
  }
}
b,
sup,
sub,
u,
del {
  color: #cea06c;
}
h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1em;
}
.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
  color: #87898f;
}
.button-area {
  background: #f9f9ff;
}
.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee;
}
.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px;
}
.button-group-area .genric-btn:last-child {
  margin-right: 0;
}
.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: 0.8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.genric-btn:focus {
  outline: none;
}
.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px;
}
.genric-btn.large {
  line-height: 45px;
}
.genric-btn.medium {
  line-height: 30px;
}
.genric-btn.small {
  line-height: 25px;
}
.genric-btn.radius {
  border-radius: 3px;
}
.genric-btn.circle {
  border-radius: 20px;
}
.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.genric-btn.arrow span {
  margin-left: 10px;
}
.genric-btn.default {
  color: #fffaf4;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.default-border:hover {
  color: #fffaf4;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.primary {
  color: #fff;
  background: #cea06c;
  border: 1px solid transparent;
}
.genric-btn.primary:hover {
  color: #cea06c;
  border: 1px solid #cea06c;
  background: #fff;
}
.genric-btn.primary-border {
  color: #cea06c;
  border: 1px solid #cea06c;
  background: #fff;
}
.genric-btn.primary-border:hover {
  color: #fff;
  background: #cea06c;
  border: 1px solid transparent;
}
.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}
.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}
.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff;
}
.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent;
}
.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}
.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}
.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff;
}
.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent;
}
.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}
.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}
.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff;
}
.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent;
}
.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}
.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}
.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff;
}
.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent;
}
.genric-btn.link {
  color: #fffaf4;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent;
}
.genric-btn.link:hover {
  color: #fffaf4;
  border: 1px solid #f9f9ff;
  background: #fff;
}
.genric-btn.link-border {
  color: #fffaf4;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline;
}
.genric-btn.link-border:hover {
  color: #fffaf4;
  background: #f9f9ff;
  border: 1px solid transparent;
}
.genric-btn.disable {
  color: #222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed;
}
.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #fff;
  border-left: 2px solid #cea06c;
}
@media (max-width: 991px) {
  .progress-table-wrap {
    overflow-x: scroll;
  }
}
.progress-table {
  background: #fff;
  padding: 15px 0px 30px 0px;
  min-width: 800px;
}
.progress-table .serial {
  width: 11.83%;
  padding-left: 30px;
}
.progress-table .country {
  width: 28.07%;
}
.progress-table .visit {
  width: 19.74%;
}
.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px;
}
.progress-table .table-head {
  display: flex;
}
.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
  color: #fffaf4;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500;
}
.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex;
}
.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
  display: flex;
  align-items: center;
}
.progress-table .table-row .country img {
  margin-right: 15px;
}
.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent;
}
.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px;
}
.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6;
}
.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686;
}
.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359;
}
.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf;
}
.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf;
}
.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6;
}
.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7;
}
.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686;
}
.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single-gallery-image:hover {
  opacity: 0.8;
}
.list-style {
  width: 14px;
  height: 14px;
}
.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important;
}
.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #cea06c;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%;
}
.ordered-list {
  margin-left: 30px;
}
.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #cea06c;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list li span {
  font-weight: 300;
  color: #87898f;
}
.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #cea06c;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list-alpha li span {
  font-weight: 300;
  color: #87898f;
}
.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #cea06c;
  font-weight: 500;
  line-height: 1.82em !important;
}
.ordered-list-roman li span {
  font-weight: 300;
  color: #87898f;
}
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #fff;
  padding: 0 20px;
}
.single-input:focus {
  outline: none;
}
.input-group-icon {
  position: relative;
}
.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3;
}
.input-group-icon .icon i {
  color: #797979;
}
.input-group-icon .single-input {
  padding-left: 45px;
}
.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #fff;
  padding: 0 20px;
  height: 100px;
  resize: none;
}
.single-textarea:focus {
  outline: none;
}
.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}
.single-input-primary:focus {
  outline: none;
  border: 1px solid #cea06c;
}
.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}
.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55;
}
.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #fff;
  padding: 0 20px;
}
.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359;
}
.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #cea06c;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.default-switch input:checked + label {
  left: 19px;
}
.single-element-widget {
  margin-bottom: 30px;
}
.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.primary-switch input:checked + label:after {
  left: 19px;
}
.primary-switch input:checked + label:before {
  background: #cea06c;
}
.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}
.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.confirm-switch input:checked + label:after {
  left: 19px;
}
.confirm-switch input:checked + label:before {
  background: #4cd3e3;
}
.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.primary-checkbox input:checked + label {
  border: none;
}
.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.confirm-checkbox input:checked + label {
  border: none;
}
.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3;
}
.disabled-checkbox input:checked + label {
  border: none;
}
.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.primary-radio input:checked + label {
  border: none;
}
.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.confirm-radio input:checked + label {
  border: none;
}
.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
}
.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3;
}
.disabled-radio input:checked + label {
  border: none;
}
.default-select {
  height: 40px;
}
.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff;
  padding-left: 20px;
  padding-right: 40px;
}
.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}
.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px;
}
.default-select .nice-select .list .option.selected {
  color: #cea06c;
  background: transparent;
}
.default-select .nice-select .list .option:hover {
  color: #cea06c;
  background: transparent;
}
.default-select .current {
  margin-right: 50px;
  font-weight: 300;
}
.default-select .nice-select::after {
  right: 20px;
}
@media (max-width: 991px) {
  .left-align-p p {
    margin-top: 20px;
  }
}
.form-select {
  height: 40px;
  width: 100%;
}
.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff !important;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%;
}
.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px;
}
.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px;
}
.form-select .nice-select .list .option.selected {
  color: #cea06c;
  background: transparent;
}
.form-select .nice-select .list .option:hover {
  color: #cea06c;
  background: transparent;
}
.form-select .current {
  margin-right: 50px;
  font-weight: 300;
}
.form-select .nice-select::after {
  right: 20px;
}
.footer-area {
  background: #fffaf4;
}
@media (max-width: 991px) {
  .footer-area .single-footer-widget {
    margin-bottom: 30px;
  }
}
.footer-area .single-footer-widget h4 {
  color: #ffffff;
  margin-bottom: 35px;
}
@media (max-width: 1024px) {
  .footer-area .single-footer-widget h4 {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .footer-area .single-footer-widget h4 {
    margin-bottom: 15px;
  }
}
.footer-area .single-footer-widget ul {
  padding-left: 0;
}
.footer-area .single-footer-widget ul li {
  margin-bottom: 15px;
  list-style: none;
}
.footer-area .single-footer-widget ul li a {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #87898f;
}
.footer-area .single-footer-widget ul li a:hover {
  color: #ffffff;
}
.footer-area .single-footer-widget .form-wrap {
  margin-top: 25px;
}
.footer-area .single-footer-widget input {
  height: 40px;
  border: none;
  width: 80% !important;
  font-weight: 300;
  background: #9b8c81;
  color: #ffffff;
  border-radius: 0;
  font-size: 14px;
  padding: 10px;
  padding-left: 20px;
}
.footer-area .single-footer-widget input:focus {
  outline: none;
  box-shadow: none;
}
.footer-area .single-footer-widget .click-btn {
  background: #fff;
  background-size: 200% auto;
  color: #fffaf4;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 8px 20px;
  margin-left: -60px;
  font-size: 15px;
  text-transform: capitalize;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 500;
  border: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer-area .single-footer-widget .click-btn span {
  font-weight: 500;
}
.footer-area .single-footer-widget .click-btn:focus {
  outline: none;
  box-shadow: none;
}
.footer-area .single-footer-widget .click-btn:hover {
  background-position: right center;
}
.footer-area .footer-bottom {
  margin-top: 50px;
}
@media (max-width: 991px) {
  .footer-area .footer-bottom {
    margin-top: 34%;
  }
}
.footer-area .footer-bottom a {
  color: #cea06c;
}
.footer-area .footer-bottom .footer-social {
  text-align: right;
}
.footer-area .footer-bottom .footer-social a {
  background: #9b8c81;
  width: 20px;
  width: 40px;
  display: inline-table;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.footer-area .footer-bottom .footer-social a:hover {
  background: #cea06c;
}
.footer-area .footer-bottom .footer-social a:hover i {
  color: #fffaf4;
}
.footer-area .footer-bottom .footer-social i {
  color: #cccccc;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991px) {
  .footer-area .footer-bottom .footer-social {
    text-align: left;
    margin-top: 20px;
  }
}
